<template>
  <div class="stageline-side-by-side-viewer" :class="{ 'read-only': readOnly }">
    <div v-if="!readOnly" class="resource-fields mr-2">
      <document-progress-bar />
      <template v-if="pdfViewerLoaded">
        <pdf-schema-form
          @previousSlide="$emit('previous-slide')"
          @previousGroup="previousGroup"
          @nextGroup="nextGroup"
          @completed="$emit('completed')"
          @show-contact-modal="$emit('show-contact-modal')"
        />
        <ct-auto-save
          v-if="savingData"
          :size="'75px'"
        />
      </template>
      <ct-centered-spinner v-else />
    </div>

    <div class="stageline-pdf-viewer-container">
      <stageline-pdf-viewer
        ref="pdf-viewer"
        :read-only="readOnly"
        :show-pagination="showPagination"
        @loaded="pdfViewerLoaded = true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StagelineSideBySideViewer',
  components: {
    PdfSchemaForm:       () => import('@/components/StagelineV2/schemaForm/pdf/PdfSchemaForm'),
    StagelinePdfViewer:  () => import('./StagelinePdfViewer'),
    DocumentProgressBar: () => import('./DocumentProgressBar'),
    CtCenteredSpinner:   () => import('@/components/shared/CtCenteredSpinner'),
    CtAutoSave:          () => import('@/components/shared/CtAutoSave'),
  },
  props: {
    readOnly: null,
  },
  data() {
    return {
      pdfViewerLoaded: false,
      savingData: false,
      autoSaveInterval: null,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentSlide',
    ]),
    ...mapGetters('stagelineSchemaForm', [
      'allSchemaFields',
      'currentField',
      'groupId',
      'resource',
    ]),
    groupIdList() {
      const groupIdList = []
      this.allSchemaFields.forEach(field => {
        if (!groupIdList.includes(this.groupId(field))) groupIdList.push(this.groupId(field))
      })
      return groupIdList
    },
    currentGroupIndex() {
      return this.groupIdList.indexOf(this.groupId(this.currentField))
    },
    showPagination() {
      return (this.currentSlide?.config?.page_navigation_enabled && this.resource?.pages?.length > 1)
        || false
    },
  },
  async mounted() {
    if (!this.readOnly) await this.autoSave()
    await this.getCurrentFieldIndex()
  },
  beforeDestroy() {
    clearInterval(this.autoSaveInterval)
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'updateFormData',
      'getCurrentFieldIndex',
      'updateCurrentFieldIndex',
    ]),
    previousGroup() {
      const previousGroupId = this.groupIdList[this.currentGroupIndex - 1]
      const previousGroupIndex = this.allSchemaFields.findIndex(f => this.groupId(f) === previousGroupId)
      this.updateCurrentFieldIndex(previousGroupIndex)
    },
    nextGroup() {
      const nextGroupId = this.groupIdList[this.currentGroupIndex + 1]
      const nextGroupIndex = this.allSchemaFields.findIndex(f => this.groupId(f) === nextGroupId)
      this.updateCurrentFieldIndex(nextGroupIndex)
    },
    autoSave() {
      this.autoSaveInterval = setInterval(() => {
        setTimeout(async () => {
          this.savingData = true
          await this.updateFormData()
        }, 16000)

        this.savingData = false
      }, 20000)
    },
  },
}
</script>

<style scoped lang="scss">
.stageline-side-by-side-viewer {
  display: flex;
  align-items: flex-start;
  column-gap: 2em;
  justify-content: center;

  &.read-only {
    display: block;
    width: 100%;
  }
  .resource-fields {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 400px;
    max-width: 520px;
  }
  .stageline-pdf-viewer-container {
    display: flex;
  }
}

@media only screen and (max-width: 1164px) {
  .resource-fields {
    min-width: unset !important;
  }
}

@media only screen and (max-width: 767px) {
  .stageline-side-by-side-viewer {
    display: block;

    .stageline-pdf-viewer-container {
      display: none;
    }
  }
  .resource-fields {
    max-width: unset !important;
    display: block;
    margin-right: 0 !important;
    .pdf-schema-form {
      max-width: unset !important;
    }
    ::v-deep .resource-field {
      * {
        align-self: self-start;
      }
    }
  }
}
</style>

